/* eslint-disable indent */
import styled from 'styled-components';

import { Spacers } from '@styles/theme';

import { getLabelTypography } from '@components/Common';
import { InputFieldProps } from '@components/Input/interfaces';

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const InputField = styled.input<InputFieldProps>`
  padding: ${Spacers.ML}rem ${Spacers.BASE}rem ${Spacers.SM}rem;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  width: 100%;
  height: ${Spacers.XL}rem;
  border: 1px solid
    ${(props) =>
      props.$hasError ? props.theme.colors.actionError : props.theme.colors.backgroundDark};
  border-radius: 0.5rem;
  outline: none;
  color: ${(props) =>
    props.$hasError ? props.theme.colors.actionError : props.theme.colors.typographyBodyLight};

  &::placeholder {
    opacity: 0.5;
    color: ${(props) =>
      props.$hasError ? props.theme.colors.actionError : props.theme.colors.typographyBodyLight};
  }

  &:focus {
    border-color: ${(props) =>
      props.$hasError ? props.theme.colors.actionError : props.theme.colors.backgroundDark};
  }
`;

export const ErrorLabel = styled.strong`
  ${getLabelTypography}
  color: ${(props) => props.theme.colors.actionError};
  margin-left: auto;
  margin-bottom: ${Spacers.XS}rem;
`;
