'use client';

import { useEffect } from 'react';

import { useSession } from 'next-auth/react';

import { useNavigate } from 'hooks/useNavigation';

import { Login } from '@components/Login';

/**
 * LoginPage component renders the login page of the application.
 */
export default function LoginPage() {
  const { status } = useSession();
  const { goToPath } = useNavigate();

  useEffect(() => {
    if (status === 'authenticated') {
      goToPath('/assignments');
    }
  }, [status, goToPath]);

  return <Login />;
}
