import Link from 'next/link';

import styled from 'styled-components';

import { Spacers } from '@styles/theme';

export const LoginWrapper = styled.div``;

export const ForgotPasswordLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: ${Spacers.LG}rem;
  color: ${({ theme }) => theme.colors.actionDefault};
  font-size: ${({ theme }) => theme.typography.action.fontSize};
  font-weight: ${(props) => props.theme.typography.action.fontWeight};
  line-height: ${(props) => props.theme.typography.action.lineHeight};
`;
